<template>
  <div v-if="data && data.checklist">
    <div :class="Status.StatusChecklist[data.checklist.status].class" class="flex items-center">
      <u-icon name="clipboard-list-check" type="fa" icon-style="solid" class="text-grey m-r-xs" style="font-size: 14px" />
      {{ Status.StatusChecklist[data.checklist.status].title }}
    </div>
  </div>
  <div v-else-if="data">{{data.observacoes}}</div>
  <div v-else>Checklist/Vistoria ainda não realizada</div>
</template>

<script>
import * as Status from "@/domain/remocao/helpers/Checklist"

export default {
  name: 'StatusChecklist',
  props: ['data'],
  computed: {
    Status () {
      return Status
    }
  }
}
</script>
