<template>
  <div class="w-full">
    <div class="font-sb font-12 m-b-sm">Histórico</div>
    <div class=" flex col-grow justify-between h-full">
        <e-window-table class="col-grow h-full bg-white"
                        :columns="['Data', 'Status', 'Arquivos', 'Observações', 'Opções']"
        >
          <e-tr v-for="(data, index) in model" :key="index">
            <e-td style="width: 150px">{{ data.data.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</e-td>
            <e-td>
              <div v-if="status(data.status)" class="flex items-center">
                <status-remocao :solicitacao="data" /> <span class="m-l-xs" v-if="data.aceite">(aceito)</span>
              </div>
            </e-td>
            <e-td class="text-nowrap">
              <div v-if="data.checklist && data.checklist.arquivos">
                <div class="flex items-center" v-if="data.checklist.arquivos.length">
                  <div @click="openFotos(data, solicitacao)" class="flex inline items-center cursor-pointer">
                    <u-icon name="camera-retro" type="fa" icon-style="solid" class="m-r-xs" /> {{countFotos(data)}}</div>
                </div>
                <div class="flex items-center text-grey-7" v-else-if="typeof data.checklist.arquivos !== 'undefined' && Number(data.checklist.arquivos.length) === 0">
                  <u-icon name="camera-retro" type="fa" icon-style="solid" class="m-r-xs text-grey-7" /> Sem foto
                </div>
              </div>
            </e-td>
            <e-td>
              <div v-if="!semChecklist(data)">{{data.observacoes}}</div>
              <div @click="openChecklist(solicitacao, remocao, data.checklist)" class="cursor-pointer" v-else>
                <status-checklist :data="data" />
              </div>
            </e-td>
            <e-td><a v-if="Number(status(data.status)) >= 99" @click="openChecklist(solicitacao, remocao, data.checklist)"><i class="erp-icon search min"></i>
            </a></e-td>
          </e-tr>
          <e-tr v-if="solicitacao.autorizacaoEntrega">
            <e-td class="font-10" style="border-bottom: none; font-size: 10px" colspan="100%">
              <u-icon name="check" type="fa" class="text-positive" /> <strong>Entrega autorizada</strong> em {{solicitacao.autorizacaoEntrega.data.date|formatDate('dd/MM/yyyy HH:mm:ss')}} por <a style="text-decoration: underline" class="text-black">{{solicitacao.autorizacaoEntrega.usuario.pessoa.nome}}</a>
            </e-td>
          </e-tr>
        </e-window-table>
    </div>
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable} from 'uloc-vue-plugin-erp'
import * as Status from '../../../../../domain/remocao/helpers/Status'
import StatusRemocao from '../../include/StatusSolicitacao'
import windowChecklist from '../../../windows/checklist'
import windowFotos from '../../../windows/fotos'
import StatusChecklist from './StatusChecklist'

export default {
  name: 'HistoricoSolicitacao',
  props: {
    solicitacao: {
      required: true
    },
    remocao: {
      required: true
    },
  },
  components: {
    StatusChecklist,
    StatusRemocao,
    EWindowTable,
    ETr,
    ETd
  },
  data () {
    return {
      model: this.solicitacao.historico
    }
  },
  mounted() {
    console.log(this.remocao)
  },
  watch: {},
  computed: {},
  methods: {
    semChecklist (data) {
      if (typeof data.checklist === 'undefined' || data.checklist === null) {
        return false
      }
      if (data.checklist.enviado === null) {
        return false
      }
      return true
    },
    status (s) {
      if (typeof s !== 'undefined') {
        return String(s)
      }
      return false
    },
    openChecklist: windowChecklist,
    openFotos: windowFotos,
    countFotos (h) {
      let fotos
      if (h.vistoriador) {
        fotos = h.checklist.arquivos.filter(f => [2].includes(Number(f.tipo)))
      } else {
        fotos = h.checklist.arquivos.filter(f => [1].includes(Number(f.tipo)))
      }
      const s = fotos.length > 1 ? 's' : ''
      return `${fotos.length} foto${s}`
      /* {{data.checklist.arquivos.length}} foto{{ data.checklist.arquivos.length > 1 ? 's' : '' }} */
    }
  }
}
</script>
