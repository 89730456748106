export default function (historico, s) {
  console.log(historico)
  let window = historico.checklist.id ? `solicitacao-remocao-fotos.${historico.checklist.id}` : 'solicitacao-fotos.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Fotos realizadas pelo ' + (historico.vistoriador ? 'vistoriador' : 'reboquista'),
    width: '90%',
    height: '90%',
    minHeight: '500px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: '_overflow-hidden',
    props: {
      historico: historico,
      solicitacao: s
    }
  }, () => import('../components/window/Fotos.vue'))
    .then((wid) => {
      console.log(wid)
    }) // return wid
}
