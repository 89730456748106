<template>
  <div v-if="!loading && !errors && !success">
    <div class="wrapper-xl m-t-n m-l-n-lg">
      <div class="row">
        <div class="col" style="max-width: 40%; padding-right: 40px">
          <div>
            <erp-s-field
                view="lr"
                label="Solicitação"
                label-width="100px"
            >
              <erp-input size="2" simple-border v-model="solicitacao.id" readonly />
            </erp-s-field>
          </div>

          <div class="m-t-xs">
            <erp-s-field
                view="lr"
                label="Data Registro"
                label-width="100px"
            >
              <erp-input simple-border readonly v-model="solicitacao.createdAt" v-mask="'##/##/#### ##:##:##'" />
            </erp-s-field>
          </div>

          <div class="m-t-xs">
            <status-solicitacao-select label-width="100px" :disable="!solicitacao.id" label="Status:" placeholder="Selecione"
                           field-view="lr"
                           v-model="solicitacao.status" />
          </div>
        </div>
        <div v-if="solicitacao.reboquista" class="col">
          <div class="row m-r-n">
            <local-detalhes label="Origem" v-model="solicitacao.origem" />
            <local-detalhes label="Destino" v-model="solicitacao.destino" />
          </div>
          <div class="row m-t-sm">
            <reboquista-info :solicitacao="solicitacao" />
          </div>
        </div>
      </div>

      <div class="row m-t">
        <historico-solicitacao :remocao="remocaoContainer.remocao" :solicitacao="solicitacao" />
      </div>
    </div>
  </div>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Solicitação atualizada com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  // WindowContent,
  // WindowBody
  ErpSField,
  ErpInput
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import {findSolicitacao as find} from '../../../../domain/remocao/services'
import {date} from 'uloc-vue'
import StatusSolicitacaoSelect from '../include/StatusSolicitacaoSelect'
import LocalDetalhes from './SolicitacaoComponent/LocalDetalhes'
import HistoricoSolicitacao from './SolicitacaoComponent/Historico'
import ReboquistaInfo from './SolicitacaoComponent/ReboquistaInfo'

let mockSolicitacao = {
  id: null,
  origem: {
    uf: null,
    cidade: null,
    localizacao: null,
    endereco: null,
    lat: null,
    lng: null
  },
  destino: {
    uf: null,
    cidade: null,
    localizacao: null,
    endereco: null,
    lat: null,
    lng: null
  },
  reboquista: null,
  custo: null,
  observacaoReboquista: null,
  observacaoInterna: null,
  data: {date: null}
}

export default {
  name: 'RemocaoWindow',
  props: ['id', 'remocao', 'remocaoContainer'],
  provide: function () {
    return {
      remocaoContainer: this.remocaoContainer
    }
  },
  data () {
    return {
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      solicitacao: JSON.parse(JSON.stringify(mockSolicitacao))
    }
  },
  mounted () {
    this.id && find(this.remocao, this.id)
      .then(response => {
        this.loading = false
        let data = response.data
        if (data.data && data.data.date) {
          data.data = date.formatDate(data.data.date, 'DD/MM/YYYY HH:mm:ss')
        }
        if (data.createdAt && data.createdAt.date) {
          data.createdAt = date.formatDate(data.createdAt.date, 'DD/MM/YYYY HH:mm:ss')
        }

        this.solicitacao = data
      })
      .catch((error) => {
        this.$uloc.dialog({
          title: 'Falha ao carregar!',
          color: 'negative',
          message: `Não conseguimos carregar a solicitação de remoção, entre em contato com o administrador.`
        })
        this.loading = false
        this.errors = error.data
        console.error(error, error.data)
      })
  },
  computed: {},
  components: {
    ReboquistaInfo,
    HistoricoSolicitacao,
    LocalDetalhes,
    StatusSolicitacaoSelect,
    // WindowBody,
    // WindowContent,
    ErpSField,
    ErpInput,
    WindowLoading
  }
}
</script>
