export const STATUS_EM_PREENCHIMENTO = 0
export const STATUS_ENVIADO = 1
export const STATUS_VISTORIADO_SEM_DIVERGENCIA = 100
export const STATUS_VISTORIADO_COM_DIVERGENCIA = 101

export const StatusChecklist = {
    [STATUS_EM_PREENCHIMENTO]: {title: 'Checklist em preenchimento', class: 'text-black'},
    [STATUS_ENVIADO]: {title: 'Checklist enviado', class: 'text-info'},
    [STATUS_VISTORIADO_SEM_DIVERGENCIA]: {title: 'Checklist vistoriado sem divergências', class: 'text-positive'},
    [STATUS_VISTORIADO_COM_DIVERGENCIA]: {title: 'Checklist vistoriado com divergência(s)', class: 'text-negative'}
}