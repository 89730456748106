<template>
  <div class="col font-11 m-r" style="width: 50%">
    <div class="font-bold small">{{label}}</div>
    <div class="m-t-xs">
      <span v-if="value.cidade">{{value.cidade.nome}} - {{value.uf.nome}}</span>
      <span v-else>{{value.cidadeOld}} - {{value.ufOld}}</span>
      <div v-if="value.localizacao" class="m-t-xxs">
        {{value.localizacao}}
      </div>
      <div class="m-t-xxs">
        {{value.endereco}}<br>
        <div v-if="!nomap" class="small font-thin m-t-xxs">Latitude: {{value.lat}}, Longitude:
          {{value.lng}}
        </div>
        <div v-if="!nomap" class="m-t-xs">
          <u-btn @click="remocaoContainer.mostraMapaLocal({...value, latLng: {lat: value.lat, lng: value.lng}}, label.toLowerCase())" size="xs" class="m-l-n-sm__" flat push icon="place" rounded round />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocalDetalhes',
  props: {
    label: {required: true},
    value: {required: true},
    nomap: Boolean
  },
  inject: ['remocaoContainer']
}
</script>

<style scoped>

</style>
