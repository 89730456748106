export default function (solicitacao, remocao, checklist) {
  let window = solicitacao.id ? `solicitacao-remocao-checklist.${solicitacao.id}` : 'solicitacao-checklist.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Checklist Remoção',
    width: '80%',
    height: '90%',
    minHeight: '500px',
    backdrop: true,
    clickOutside: false,
    fullscreen: true,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden__',
    maximize: true,
    props: {
      solicitacao: solicitacao,
      remocao: remocao,
      checklist: checklist
    }
  }, () => import('../components/window/Checklist.vue'))
    .then((wid) => {
      console.log(wid)
    }) // return wid
}
