<template>
  <erp-label label="Dados sobre o reboquista">
    <div class="uppercase">{{solicitacao.reboquista.pessoa.name}}</div>
    <e-row class="m-t-xxs">
      <e-col class="col">{{solicitacao.reboquista.pessoa.document}}</e-col>
      <e-col class="col" v-if="solicitacao.motorista">{{solicitacao.motorista.nome}} -
        {{solicitacao.motorista.documento}}
      </e-col>
      <div v-else>Motorista não definido</div>
    </e-row>
    <e-row class="m-t-sm" v-if="solicitacao.reboque">
      Reboque:<span class="uppercase m-l-xs">{{solicitacao.reboque.placa}} - {{solicitacao.reboque.descricao}}</span>
    </e-row>
    <e-row v-else class="m-t-sm">
      Reboque ainda não definido
    </e-row>
    <e-row class="m-t-xs"> <!-- @TODO -->
      <e-col v-if="solicitacao.reboquista.estatistica">
        Quantidade de reboques realizados: {{solicitacao.reboquista.estatistica.remocoes}}
      </e-col>
      <e-col class="col">
        Início do cadastro: {{solicitacao.reboquista.createdAt.date|formatDate('dd/MM/yyyy')}}
      </e-col>
    </e-row>
  </erp-label>
</template>

<script>
import {ErpLabel} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
export default {
  name: 'ReboquistaInfo',
  props: ['solicitacao'],
  components: {ECol, ERow, ErpLabel}
}
</script>
